<template>
  <!-- 实名说明 -->
  <app-container :title="'实名认证'" :background="tool.getThemeList(theme)">
    <div class="new-auth-telecom">
      <div class="new-auth-telecom-top">
        <div
          class="new-auth-telecom-top-item"
          :class="{ active: currentActive > item.id }"
          v-for="item in stepList"
          :key="item.id"
        >
<!--          @click="jumpStep(item)"-->
          <div class="new-auth-telecom-top-item-num">{{ item.id }}</div>
          <div v-if="item.id != 4">
            <img
              v-if="currentActive - 1 > item.id"
              src="../../assets/image/newAuthTelecom/img5.png"
              style="width: 45px; position: absolute; left: 48px; top: 0"
              alt=""
            />
            <img
              v-else
              src="../../assets/image/newAuthTelecom/img1.png"
              style="width: 45px; position: absolute; left: 48px; top: 0"
              alt=""
            />
          </div>
          <div class="new-auth-telecom-top-item-text">{{ item.title }}</div>
        </div>
        <div class="top-banner">
          <img src="../../assets/image/newAuthTelecom/img3.png" alt="" />
        </div>
      </div>
      <div class="new-auth-telecom-content">
        <div>
          <van-form v-if="currentActive == 2" @submit="onSubmit">
            <van-cell-group inset>
              <van-field
                v-model="cardNo"
                name="cardNo"
                label="卡号:"
                placeholder="请输入卡号"
                input-align="right"
                clearable
                :rules="[{ required: true, message: '请输入卡号' }]"
              />
              <van-field
                v-model="realName"
                name="realName"
                label="真实姓名:"
                input-align="right"
                placeholder="请输入真实姓名2-25个字符"
                :minlength="2"
                :maxlength="25"
                clearable
                :rules="[
                  { required: true, message: '请填写真实姓名' },
                  { validator, message: '请输入简体中文,字符限制2-25位'},
                  { max: 25, message: '最多25位'},
                ]"
              />
              <van-field
                v-model="idCard"
                name="idCard"
                label="身份证号:"
                input-align="right"
                placeholder="请输入18位身份证有效号码"
                :minlength="18"
                :maxlength="18"
                clearable
                :rules="[
                  { required: true, message: '请填写18位有效身份证号' },
                  { validator: idCardValidator, message: '请填写有效的成年人身份证号码' }
                ]"
              />
              <div style="width: 100px;height: 1px;background: transparent"></div>
            </van-cell-group>
            <!--                  @click="handlerNext"-->
            <div style="margin-top: 20px">
              <!-- color="#337EFE" -->
              <van-button
                round
                block
                style="border-radius: 8px"
                type="primary"
                native-type="submit"
                :disabled="!(cardNo && realName && idCard)"
                :color="!(cardNo && realName && idCard)? '#9FA6AF': '#337EFE'"
              >
                下一步
              </van-button>
            </div>
          </van-form>
          <div v-if="currentActive == 3" class="step2">
            <div class="step2-top">
              <div class="step2-top-content">
                根据国家对通信产品的实名制要求,需要您上传证件照,我们将严格保证您的信息安全。
              </div>
            </div>
            <div class="step2-content">
              <input
                style="display: none"
                type="file"
                accept="image/*"
                ref="uploadFile"
                @change="uploadImg($event)"
              />
              <div class="step2-content-item" @click="handleuploadFile">
                <img
                  v-if="idCardIn"
                  class="step2-content-item-bg"
                  :src="idCardIn"
                  alt=""
                />
                <img
                  v-else
                  class="step2-content-item-bg"
                  src="../../assets/image/newAuthTelecom/img13.png"
                  alt=""
                />
                <img
                  v-if="!idCardIn"
                  class="step2-content-item-icon"
                  src="../../assets/image/newAuthTelecom/img9.png"
                  alt=""
                />
                <img
                  v-else
                  class="step2-content-item-icon"
                  src="../../assets/image/newAuthTelecom/img19.png"
                  alt=""
                />
                <div class="tips">
                  <div>请上传身份证人像页</div>
                  <div>单张图片大小不超过1MB</div>
                </div>
              </div>
              <div class="step2-content-item" @click="handleUploadImgOut">
                <img
                  v-if="idCardOut"
                  class="step2-content-item-bg"
                  :src="idCardOut"
                  alt=""
                />
                <img
                  v-else
                  class="step2-content-item-bg"
                  src="../../assets/image/newAuthTelecom/img23.png"
                  alt=""
                />
                <img
                  v-if="!idCardOut"
                  class="step2-content-item-icon"
                  src="../../assets/image/newAuthTelecom/img9.png"
                  alt=""
                />
                <img
                  v-else
                  class="step2-content-item-icon"
                  src="../../assets/image/newAuthTelecom/img19.png"
                  alt=""
                />
                <div class="tips">
                  <div>请上传身份证国徽页</div>
                  <div>单张图片大小不超过1MB</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="[4, 5].includes(currentActive)" class="face-view">
            <div class="video-upload">
<!--         视频认证是否成功  isUploadVideoSuccess-->
              <img v-if="currentActive == 5 && isUploadVideoSuccess"
                src="../../assets/image/newAuthTelecom/img7.png"
                alt=""
              />
              <img v-if="currentActive == 5 && !isUploadVideoSuccess"
                   src="../../assets/image/newAuthTelecom/img21.png"
                   alt=""
              />
              <img
                v-if="currentActive == 4"
                src="../../assets/image/newAuthTelecom/img15.png"
                alt=""
              />
            </div>
            <p v-if="currentActive == 5 && !isUploadVideoSuccess" style="text-align: center;color: #FF3232;font-size: 16px;">认证失败，请重新进行认证！</p>
            <van-form style="width: 100%" readonly v-if="currentActive == 5 && isUploadVideoSuccess">
              <van-cell-group inset>
                <van-field
                  v-model="cardNo"
                  name="ICCID/卡号"
                  label="ICCID/卡号:"
                  placeholder="请输入ICCID/卡号"
                  input-align="right"
                  :rules="[{ required: true, message: '请输入ICCID/卡号' }]"
                />
                <van-field
                  v-model="realName"
                  name="真实姓名"
                  label="真实姓名:"
                  input-align="right"
                  placeholder="请输入真实姓名"
                  :rules="[{ required: true, message: '请填写真实姓名' }]"
                />
                <van-field
                  v-model="idCard"
                  name="身份证号"
                  label="身份证号:"
                  input-align="right"
                  placeholder="请输入18位身份证号码"
                  :rules="[{ required: true, message: '请填写身份证号' }]"
                />
                <div style="width: 100px;height: 1px;background: transparent"></div>
              </van-cell-group>
            </van-form>
            <div class="face-tip" v-if="currentActive == 4">
              此步骤进行刷脸认证,请确保为本人操作
            </div>
            <div class="new-auth-telecom-top" v-if="currentActive == 4"
              style="background: #ffffff; padding: 16px"
            >
              <div
                class="new-auth-telecom-top-item active"
                style="background: #ffffff; height: 72px"
                v-for="item in step4List"
                :key="item.id"
              >
                <div
                  class="new-auth-telecom-top-item-num"
                  style="background: #337efe; color: #ffffff"
                >
                  {{ item.id }}
                </div>
                <div
                  class="new-auth-telecom-top-item-text"
                  style="color: #242e3d; margin-top: 8px"
                >
                  {{ item.title }}
                </div>
                <div
                  class="new-auth-telecom-top-item-text"
                  style="color: #242e3d; margin-top: 0px; line-height: 12px"
                >
                  {{ item.subTitle }}
                </div>
              </div>
            </div>
          </div>
          <div :style="{marginTop: currentActive==4?'0px':'20px'}">
            <van-button
              v-if="currentActive!=2"
              :disabled="currentActive == 3 && !(idCardIn && idCardOut)"
              @click="handlerNext"
              round
              block
              style="border-radius: 8px"
              type="primary"
              :color="
                currentActive == 3 && !(idCardIn && idCardOut)
                  ? '#9FA6AF'
                  : '#337EFE'
              "
              native-type="submit"
            >
              {{ currentActive != 5 ? (currentActive !== 4 ? "下一步": "开始") : isUploadVideoSuccess?"完成":"重新录制" }}
            </van-button>
            <input
              style="display: none"
              type="file"
              accept="video/*"
              ref="fileRef"
              id="fileRef"
              capture="user"
              @change="changeHandle"
            />
            <video
              width="300"
              autoplay
              id="showVideo"
              controls
              loop
              v-show="false"
            >
              <source :src="localUrl" />
            </video>
          </div>
          <div class="tip-box" v-if="currentActive !== 5">
            <span class="tip-box-title">温馨提示：</span>
            <div class="tip-box-list" v-if="currentActive == 2">
              <p class="tip-box-list-item">
                1. 请在15分钟内完成实名认证，否则提交无效。
              </p>
              <p class="tip-box-list-item">
                2. 如果信息不一致将会提示认证失败。
              </p>
              <p class="tip-box-list-item">
                3. 如果认证失败,请尝试重新输入上传。
              </p>
            </div>
            <div class="tip-box-list" v-if="currentActive == 3">
              <p class="tip-box-list-item">
                1. 身份证国徽面与正面信息应为同一身份证的信息且在有效期内；
              </p>
              <p class="tip-box-list-item">
                2. 所有上传照片需清晰且未遮挡,请勿进行美化和修改,以免认证失败；
              </p>
              <p class="tip-box-list-item">
                3. 上传图片文件格式支持png,jpg和bmp；
              </p>
              <p class="tip-box-list-item">
                4. 单张图片大小不超过1MB,尺寸最小为500x500；
              </p>
              <p class="tip-box-list-item">
                5. 所有上传信息均会被妥善保管,不会用于其他商业用途或传输给其他第三方；
              </p>
            </div>
            <div class="tip-box-list" v-if="currentActive == 4">
              <p class="tip-box-list-item">
                1. 视频录制中请保持头部居中,五官无遮挡,衣着得体；录制环境光现适中,避免过暗或过亮,人像背景尽量简洁。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay v-if="videoAuthLoading" :show="videoAuthLoading" z-index="10000" :custom-style="{background: 'rgba(0,0,0,.7)'}">
      <div class="videoAuthLoading">
        <img :src="require('_ASSETS_/image/common/loading.gif')" alt="" />
        <div>当前正在进行视频认证操作，时间可能稍微有点久，请耐心等候不要关闭页面！</div>
      </div>
    </van-overlay>
    <van-overlay v-else :show="asyncLoading" z-index="9999" :custom-style='loadingStyle'>
      <div class="wrapper" @click.stop>
        <img class="loadimg" :src="require('_ASSETS_/image/common/loading.gif')" alt="" />
      </div>
    </van-overlay>
  </app-container>
</template>

<script>
// 需要哪些模块按需引入
import { onMounted, onUnmounted, reactive, toRefs, ref, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import { authInfoCheck, ocrCardCheck, checkAuthVideo, fileUpload, fetchAuthParams, pushAuthResult } from '_API_/api.services'
import { setStore } from '@/utils/store'
import { urlAddParams, fileToImgFn, paramsToFormData } from '_UTIL_/utils.js'
import { compressAccurately } from '@/utils/compressImg.js'
import axios from 'axios'
export default {
  name: 'newAuthTelecom',
  setup () {
    // 个人觉着还是这样写舒服一点 类似于vue2中的data
    const state = reactive({
      authTimer: null,
      licenseKey: '',
      secretKey: '',
      companyId: '',
      iccid: '', // 查询出来的iccid
      card: '', // 卡号
      sim: '',
      realNameLink: '', // 通道链接
      realNameCourseLink: '', // 实名教程链接
      theme: '', // 主题名称
      subCards: [], // 多网卡子卡
      currentActive: 2, // 当前进行到 初始值 2
      loadingStyle: {
        backgroundColor: 'rgba(226,226,226,0.39)'
      },
      stepList: [
        {
          id: 1,
          title: '实名认证'
        },
        {
          id: 2,
          title: '上传证件'
        },
        {
          id: 3,
          title: '视频认证'
        },
        {
          id: 4,
          title: '认证结果'
        }
      ],
      step4List: [
        {
          id: 1,
          title: '点击开始',
          subTitle: '进行录制'
        },
        {
          id: 2,
          title: '保持正脸',
          subTitle: '屏幕居中'
        },
        {
          id: 3,
          title: '录制2~3秒',
          subTitle: '完成自动上传'
        }
      ],
      show: true,
      showOverlay: false,
      cardNo: '', // 卡号
      realName: '', // 真实姓名
      idCard: '', // 身份证号
      idCardIn: '', // 身份证正面url
      imgInFile: '',  // 身份证正面文件
      idCardOut: '',  // 身份证反面url
      imgOutFile: '', // 身份证反面文件
      localUrl: '',  // 视频本地预览地址
      time: '', // 视频时长
      size: '', // 视频大小
      type: '', // 上传正反面 1 2
      videoFile: null,  // 视频文件
      asyncLoading: false,  // 异步loading
      videoAuthLoading: false,  // 视频认证异步loading
      isUploadVideoSuccess: false // 是否验证成功
    })
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance()
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx
    // 调用useRoute,获取页面携带的参数
    const route = useRoute()
    const fileRef = ref(null)
    const uploadFile = ref(null)
    const methods = {
      // 获取认证必要参数
      async getAuthParams (cardNo) {
        // 卡号是必填, 没填不可点击 不用判空
        let { data: { code, data, msg } } = await fetchAuthParams(cardNo)
        if (code == 0) {
          state.licenseKey = data.appKey
          state.secretKey = data.apiKey
          state.companyId = data.custId
        } else {
          tool.toast({
            msg: msg,
            duration: 1000
          })
        }
      },
      /*
      * 判断平年闰年
      * y:年份数字,四位
      * */
      isOrdinaryYear (y) {
        if ((y % 4 === 0 && y % 100 !== 0) || y % 400 === 0) {
          return false
        }
        return true
      },
      /*
        * 判断是否满足18岁
        * time : 毫秒级时间戳,用户选中日期的时间戳
        * return: 校验函数返回 true 表示校验通过,false 表示不通过
        * */
      satisfy (time) {
        var nowDate = new Date() // 当前时间
        var mouth = nowDate.getMonth() + 1
        var day = nowDate.getDate()
        var year = nowDate.getFullYear() - 18
        if (mouth === 2 && day >= 28) {
          day = methods.isOrdinaryYear(year) ? 28 : 29
        }
        let timeStr = year + '-' + mouth + '-' + day
        // console.log(timeStr)
        var oldDate = new Date(timeStr.replace(/-/g, '/')).getTime()
        // console.log(oldDate > time)
        return oldDate > time
      },
      validator (val) {
        return /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,25}$/.test(val)
      },
      // 身份证校验年龄
      idCardValidator (val) {
        if (val.length == 18) {
          var subStringText = val.substring(6, 14)
          const time = `${subStringText.substring(0, 4)}-${subStringText.substring(4, 6)}-${subStringText.substring(6, 8)}`
          return methods.satisfy(new Date(time.replace(/-/g, '/')).getTime())
        }
        return false
      },
      // 下一步
      jumpStep (item) {
        if ((state.currentActive != 5) && item.id < state.currentActive - 1) {
          state.currentActive = item.id + 1
        } else {
          if (state.currentActive == 5) {
            if (state.isUploadVideoSuccess) {
              return tool.toast({
                msg: '已完成认证',
                duration: 1200
              })
            } else {
              state.currentActive = item.id + 1
            }
          }
          tool.toast({
            msg: '请按照步骤进行操作',
            duration: 1200
          })
        }
      },
      // 播放视频
      playHandle () {
        const v = document.querySelector('#showVideo')
        try {
          v.play()
        } catch (err) {
          console.log("catch", e);
        }
      },
      // inputFile 视频选中
      async changeHandle (ele) {
        var video = ele.target.files[0]
        ele.target.value = ''
        var url = window.URL.createObjectURL(video)
        const videoDom = document.querySelector('#showVideo')
        videoDom.src = url
        state.localUrl = url
        videoDom.setAttribute('playsinline', 'true')
        videoDom.setAttribute('autoplay', 'false')
        videoDom.setAttribute('preload', 'auto')
        videoDom.setAttribute('webkit-playsInline', 'true')
        videoDom.setAttribute('x-webkit-airplay', 'allow')
        videoDom.setAttribute('x5-video-player-type', 'h5')
        videoDom.setAttribute('x5-video-player-fullscreen', 'true')
        videoDom.setAttribute('x5-video-orientation', 'landscape|portrait')
        // 兼容移动端微信浏览器video
        if (window.WeixinJSBridge) {
          window.WeixinJSBridge.invoke(
            'getNetworkType',
            {},
            () => {
              try {
                videoDom.play()
                videoDom.pause()
              } catch(err) {
                console.log(err)
              }
            },
            false
          )
        } else {
          //  让移动端视频开始缓冲
          try {
            videoDom.play()
            videoDom.pause()
          } catch(err) {
            console.log(err)
          }
        }
        var a = video.size / Math.pow(1024, 2)
        var size = Math.floor(a * 10) / 10 + 'MB' // 字节转化为MB
        state.size = size
        if (size > 15) {
          return tool.toast({
            msg: '当前上传视频为 '+ size + ', 上传视频大小不得超过15M'
          })
        }
        // 赋值视频文件
        state.videoFile = video
        videoDom.addEventListener('loadedmetadata', () => {
          state.time = videoDom.duration
        })
        // 获得统计时长则去调用视频校验
        let timer = setTimeout(async () => {
          console.log('尺寸', state.size)
          console.log('时长', state.time)
          if (state.time && (state.time < 2 || state.time > 4)) {
            tool.toast({
              msg: '当前视频时长为 ' + state.time.toFixed(2) + 's , 时长应为 2 ~ 4 秒,请重新上传！',
              duration: 1500
            })
            state.showVideo = false
            // 清空value
            state.videoFile = null
            ele.target.value = ''
          } else {
            // state.videoFile = video
            clearTimeout(timer)
            await methods.checkVideoHandle(ele)
          }
        }, 500);
      },
      // 视频认证
      async checkVideoHandle (ele) {
        // state.asyncLoading = true
        // state.videoAuthLoading = true
        // let formData = new FormData()
        // formData.append('file', state.videoFile)
        // formData.append('fileType', '2')
        // 上传接口
        try {
          // let {data: uploadRes} = await fileUpload(formData)
          // let videoSrc = ''
          // if (uploadRes.code == 0) {
          //   // 上传成功
          //   videoSrc = uploadRes.data.src
          // } else {
          //   videoSrc = null
          // }
          // // 成功失败都清空源文件
          // state.videoFile = null
          // 清空掉input 被占用的 value
          // ele.target.value = ''
          // state.asyncLoading = false
          // state.videoAuthLoading = false
          // if (!videoSrc) {
          //   return tool.toast({
          //     msg: uploadRes.msg,
          //     duration: 1200
          //   })
          // }
          let { cardNo, realName, idCard } = state
          let params = {
            card: cardNo,
            name: realName,
            idNum: idCard,
            file: state.videoFile,
            licenseKey: state.licenseKey,
            secretKey: state.secretKey,
            companyId: state.companyId,
          }
          // state.asyncLoading = true
          state.videoAuthLoading = true
          checkAuthVideo(paramsToFormData(params)).then(({ data: res }) => {
            if (res.code == 0) {
              state.isUploadVideoSuccess = true
              tool.toast({
                msg: '认证成功',
                duration: 1000
              })
              // 认证通过 成功则 推送成功到 iot
              var pushParams = {
                iccid: cardNo,
                result: 0,
                message: 'success'
              }
              pushAuthResult(pushParams).then(({ data: res }) => {
                if (res.code == 0) {
                  console.log('成功')
                } else {
                  console.log('失败')
                }
              }).catch(err => {
                console.log(err)
              })
            } else {
              state.isUploadVideoSuccess = false
              tool.toast({
                msg: res.msg,
                duration: 1000
              })
            }
          }).catch(err => {
            console.log(err)
          }).finally(() => {
            // console.log('end')
            // 无论结果如何进入下一步(认证状态显示)
            // state.asyncLoading = false
            state.videoAuthLoading = false
            if (state.currentActive != 5) {
              state.currentActive++
            }
          })
        } catch (error) {
          if (error.message.includes('timeout')) {
            tool.toast({
              msg: '连接超时，请重试！',
              duration: 1200
            })
          }
          // 成功失败都清空源文件
          state.videoFile = null
          // 清空掉input 被占用的 value
          // ele.target.value = ''
          // state.asyncLoading = false
          state.videoAuthLoading = false
          throw(new Error(error))
        }
      },
      // 步骤一提交
      async onSubmit (values) {
        // console.log('submit', values)
        let {cardNo, realName, idCard} = values
        // 获取认证参数
        await methods.getAuthParams(cardNo)
        // 步骤一 进入 步骤二
        let params = {
          card: cardNo,
          name: realName,
          idNum: idCard,
          licenseKey: state.licenseKey,
          secretKey: state.secretKey,
          companyId: state.companyId
        }
        state.asyncLoading = true
        authInfoCheck(params).then(({ data: res }) => {
          // 接口调用成功
          if (res.code == 0) {
            methods.handlerNext()
            // 第一步过后开启15分钟的定时器
            state.authTimer = setTimeout(() => {
              console.log(123)
              tool.toast({
                msg: '认证超时，请重新认证！',
                duration: 1200
              })
              clearTimeout(state.authTimer)
              window.location.reload()
            }, 15 * 60 * 1000)
          } else {
            tool.toast({
              msg: res.msg,
              duration: 1000
            })
            // mock成功之后删掉
            // methods.handlerNext()
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          state.asyncLoading = false
        })
      },
      // 检测路由参数
      judgeRoute () {
        if (route.query && route.query.card) {
          state.cardNo = route.query.card
        }
      },
      handleuploadFile () {
        state.type = 1
        uploadFile.value.click()
      },
      handleUploadImgOut () {
        state.type = 2
        uploadFile.value.click()
      },
      // 上传图片
      async uploadImg (event) {
        const file = event.target.files[0]
        event.target.value = ''
        if (!file) {
          return
        }
        if (file.type != 'image/png' && file.type != 'image/jpeg' && file.type != 'image/bmp') {
          tool.toast(
            {
              msg: '只支持上传png和jpeg或bmp图片！'
            },
            () => {}
          )
          return
        }
        // if (file.size / 1024 > 1024) {
        //   tool.toast(
        //     {
        //       msg: '图片最大不得超过1MB'
        //     },
        //     () => {}
        //   )
        //   return
        // }
        // 1 正面 2 反面 (压缩)
        // 前端压缩图片, 1M以内不压缩, 大于1M压缩处理
        let compressFile = null
        if (file.size <= 1024 * 1024) {
          compressFile = file
        } else {
          state.asyncLoading = true
          compressFile = await compressAccurately(file, 900)
          state.asyncLoading = false
          console.log(compressFile.size)
        }
        if (state.type == 1) {
          state.imgInFile = compressFile
        } else {
          state.imgOutFile = compressFile
        }
        // console.log(state.imgInFile)
        // console.log(state.imgOutFile)
        // 本地的 base64图片暂不需要，采用上传成功后后端返回的oss地址
        methods.fileToImg(compressFile)
        // compressFile 压缩文件
        // let formData = new FormData()
        // formData.append('file', compressFile)
        // formData.append('fileType', '1')
        // fileUpload(formData).then(({data: res}) => {
        //   if (res.code == 0) {
        //     // 区分类型
        //     if (state.type == 1) {
        //       state.idCardIn = res.data.src
        //     } else {
        //       state.idCardOut = res.data.src
        //     }
        //   } else {
        //     tool.toast({
        //       msg: res.msg,
        //       duration: 1000
        //     })
        //     // 上传失败清空上次的图片
        //     if (state.type == 1) {
        //       state.idCardIn = null
        //     } else {
        //       state.idCardOut = null
        //     }
        //   }
        // }).catch(err => {
        //   console.log(err)
        // }).finally(() => {
        //   // console.log('end')
        // })
        // ctx.upImgFn()
      },
      // 本地暂存图片文件的 预览
      async fileToImg (file) {
        const imgUrl = await fileToImgFn(file) // 图片转换处理,图片的 base64 格式, 可以直接当成 img 的 src 属性值
        // state.imgList.push(imgUrl)
        if (state.type == 1) {
          state.idCardIn = imgUrl
        } else {
          state.idCardOut = imgUrl
        }
      },
      videoUpload () {
        // document.querySelector('#fileRef').click()
        fileRef.value.click()
      },
      async handlerNext () {
        if (state.currentActive < 5) {
          if (state.currentActive == 3) {
            if ([undefined, null, ''].includes(state.imgInFile) || [undefined, null, ''].includes(state.imgOutFile)) {
              return tool.toast({
                msg: '上传文件不能为空',
                duration: 1500
              })
            }
            // 获取表单输入值
            let {cardNo, realName, idCard} = state
            let params = {
              card: cardNo,
              name: realName,
              idNum: idCard,
              licenseKey: state.licenseKey,
              secretKey: state.secretKey,
              companyId: state.companyId
            }
            // 开启loading
            let checkOneIsOk = false
            state.asyncLoading = true
            let { data: resIn } = await ocrCardCheck(paramsToFormData({ ...params, cardType: 2, file: state.imgInFile }))
            if (resIn.code == 0) {
              console.log('正面校验完成')
              checkOneIsOk = true
            } else {
              checkOneIsOk = false
              state.asyncLoading = false
              // mock 模拟成功之后删掉
              // state.currentActive++
              return tool.toast({
                msg: resIn.msg,
                duration: 1200
              })
            }
            // 正面成功 才 进行反面校验
            if (checkOneIsOk) {
              let { data: resOut } = await ocrCardCheck(paramsToFormData({ ...params, cardType: 3, file: state.imgOutFile }))
              if (resOut.code == 0) {
                console.log('反面校验完成')
                tool.toast({
                  msg: '验证通过',
                  duration: 1200
                })
                // 下一步
                state.currentActive++
                state.asyncLoading = false
              } else {
                state.asyncLoading = false
                return tool.toast({
                  msg: resOut.msg,
                  duration: 1200
                })
              }
            }
            return
          }
          if (state.currentActive == 4) {
            // 录制视频
            methods.videoUpload()
            return
          }
          state.currentActive++
        } else {
          // 是否成功录制
          if (state.isUploadVideoSuccess) {
            tool.toast({
              msg: '认证通过',
              duration: 1500
            })
            // 设置进 全局的卡号
            store.dispatch("SetUserInfo", {
              iccid: state.cardNo
            });
            router.push({
              name: 'personal'
            })
          } else {
            // 重新录制
            methods.videoUpload()
          }
        }
      }
    }
    onMounted(async () => {
      // 获取主题名称
      state.theme = store.state.common.theme
      // url获取参数
      methods.judgeRoute()
      if ([null, undefined, ''].includes(state.cardNo)) {
        // 获取用户信息
        const info = store.state.user.userInfo
        if (Object.keys(info).length > 0) {
          state.card = info.virtualId || info.iccid
        }
      }
    })
    onUnmounted(() => {
      // 离开页面时候清除定时器
      clearTimeout(state.authTimer)
    })
    return { ...toRefs(state), ...methods, tool, fileRef, uploadFile }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.active {
  opacity: 1 !important;
}
.new-auth-telecom {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  &-top {
    width: 100%;
    background: #337efe;
    padding: 48px;
    /*padding-bottom: 32px;*/
    padding-bottom: 96px;
    /*height: 258px;*/
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .top-banner {
      position: absolute;
      bottom: 32px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        // width: 550px;
        width: 750px;
        height: 64px;
      }
    }
    &-item {
      height: 114px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background: #337efe;
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
      opacity: 0.5;
      position: relative;
      &-num {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        border-radius: 48px;
        background: #ffffff;
        color: #337efe;
        font-weight: bold;
      }
      &-text {
        color: #ffffff;
        margin-top: 16px;
        font-size: 24px;
      }
    }
  }
  &-content {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    padding: 40px;
    margin-bottom: 60px;
    box-sizing: border-box;
    background: #fff;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    /*flex: 1;*/
    /*height: calc(100% - 222px);*/
    height: 100%;
    position: relative;
    top: -32px;
    z-index: 10;
    /deep/.van-cell-group {
      margin: 0;
    }
    /deep/.van-cell:first-child {
      padding-top: 0;
    }
    /deep/.van-cell {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.tip-box {
  margin-top: 48px;
  padding: 32px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 16px;
  &-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: #242e3d;
    margin-bottom: 32px;
  }
  &-list {
    margin-top: 16px;
    /*}*/
    &-item {
      text-indent:-28px;
      margin-left:40px;
      font-size: 24px;
      line-height: 42px;
      color: #646b74;
      margin-top: 8px;
    }
  }
}
.step2 {
  width: 100%;
  min-height: 460px;
  /*margin-bottom: 40px;*/
  &-top::before {
    content: "";
    width: 4px;
    background: #337efe;
    height: 80px;
    border-radius: 4px;
    position: absolute;
    top: 8px;
    bottom: 0;
    left: 0;
  }
  &-top {
    position: relative;
    font-size: 28px;
    line-height: 48px;
    color: #242e3d;
    margin-bottom: 40px;
    &-content {
      padding-left: 16px;
    }
  }
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item {
      width: 316px;
      min-height: 328px;
      position: relative;
      .tips {
        margin-top: 48px;
        /*margin-bottom: 40px;*/
        div:first-child {
          font-weight: 400;
          font-size: 24px;
          line-height: 34px;
          letter-spacing: -0.1px;
          color: #242e3d;
        }
        div:last-child {
          font-weight: 400;
          font-size: 24px;
          line-height: 34px;
          letter-spacing: -0.1px;
          color: #9fa6af;
          margin-top: 8px;
        }
      }
      &-bg {
        width: 316px;
        height: 198px;
        display: inline-block;
      }
      &-icon {
        position: absolute;
        left: 0;
        top: 136px;
        width: 136px;
        height: 112px;
        display: inline-block;
      }
    }
  }
}
.face-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .video-upload {
    /*width: 196px;*/
    width: 212px;
    /*height: 196px;*/
    height: 212px;
    margin-bottom: 40px;
    img {
      width: 212px;
      height: 212px;
      display: inline-block;
    }
  }
  .face-tip {
    color: #337efe;
    text-align: center;
    font-size: 28px;
    line-height: 40px;
  }
}
/deep/ .van-field__error-message {
  text-align: right;
}

/*ol{list-style-type:none;list-style-position:outside;counter-reset:sectioncounter;}*/
ol{
  /*list-style:decimal inside;counter-reset:sectioncounter;*/
  list-style: decimal inside none;
  margin-left: 3em;
}
ol li:before {
  content: counter(sectioncounter) "、"; /*代表数字counter(sectioncounter)*/
  counter-increment: sectioncounter;
}
.videoAuthLoading {
    z-index: 10;
    position: absolute;
    top: calc(50% - 200px);
    /* width: 80%; */
    left: 88px;
    right: 88px;
    color: #FF3232;
    padding: 48px 88px;
    text-align: center;
    font-size: 28px;
    line-height: 48px;
    border-radius: 16px;
    background: #ffffff;
    img{
      width: 88px;
      height: 88px;
      display: inline-block;
    }
}
/deep/.van-cell:after{
  left: 0;
  right: 0;
}
/deep/.appbar{
  z-index: 1;
}
/deep/.content{
  position: relative;
  z-index: 1;
  top: -1px;
}
</style>
