import * as imageConversion from 'image-conversion'

/**
 * 压缩图片大小到具体的值(单位kb)
 * @param inputFile 传入file
 * @param toSize 转后文件的大小(kb)
 * @returns {Promise<File>} 返回结果文件Promises
 */
export async function compressAccurately (inputFile, toSize) {
  const resAvatarBlob = await imageConversion.compressAccurately(inputFile, toSize)
  const resAvatarFile = new window.File(
    [resAvatarBlob],
    inputFile.name,
    { type: inputFile.type }
  )
  return resAvatarFile
  // return resAvatarBlob
}
